<template>
    <div class="member_unit">
        <div class="fx" style="border-bottom:1px solid #ccc;padding-bottom:10px;">
            <div>会员单位申请</div>
        </div>
        <div class="content">
            <div v-if="memberunitList.order_sn">
                <div class="fx">
                    <div v-for="(item,i) in stepLists" :key="i">
                        <div v-if="item.status==memberunitList.status" class="fx">
                            <div class="box">
                                <img :src="item.img" alt="">
                                <div :style="{'color':item.color}">{{item.name}}</div>
                                <div class="desc" v-if="item.ref">{{item.desc+memberunitList.reason}}</div>
                                <div class="desc" v-else>{{item.desc}}</div>
                                <div class="btn" v-if="item.btn" @click="Jump(memberunitList,item.btn)">{{item.btn}}</div>
                            </div>
                            <img v-if="item.imgs" :src="item.imgs" alt="" style="margin:0px 10px;">
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="fx_around" style="margin-top:40px;flex-wrap:wrap;">
                    <div v-for="(item,i) in stepList" :key="i" class="fx_around" style="width:33%;margin-bottom:60px;">
                        <div class="boxs">
                            <div>{{item.name}}</div>
                            <div class="line"></div>
                            <img :src="item.img" alt="">
                            <div class="desc" v-if="i>0">{{item.desc}}</div>
                            <div class="desc" v-else>{{item.desc}}<span class="cursor" style="color:#CD0000;" @click="$router.push('/description')">入会说明</span>，了解入会须知</div>
                        </div>
                        <img v-if="item.imgs" :src="item.imgs" alt="">
                    </div>
                </div>
                <div class="Connect">
                    <div style="border-bottom:1px solid #CD0000;border-right:1px solid #CD0000;height:20px;"></div>
                    <div style="border-left:1px solid #CD0000;height:20px;"></div>
                </div>
                <div class="btns" @click="$router.push('/memberUnitIndex')">在线申请</div>
            </div>
        </div>
        <div class="yingyin" v-if="show">
            <div class="yboxs">
                <div class="fx">
                    <div>支付</div>
                    <i class="el-icon-close cursor" @click="show=false,destoryInter()"></i>
                </div>
                <div class="t-center" style="margin:30px 0px;" v-if="status==1">
                    <div style="color:#CC0000;margin-bottom:10px;">支付会费{{memberunitList.amount}}元</div>
                    <span>剩余支付时间：{{ interTime }}秒</span>
                    <div class="codeimg">
                        <div class="qrcode" ref="qrCodeUrl"></div>
                        <!-- <img :src="wechatCode.result.code_url" alt=""> -->
                    </div>
                    <img src="shexie/img/Scan_code.png" alt="">
                </div>
                <div class="t-center" v-if="status==2" style="margin-top:60px;">
                    <img src="shexie/img/success.png" alt="">
                    <div style="margin:20px 0px;">
                        <div>您已成功支付会费</div>
                    </div>
                    <div class="btns" style="margin:auto;" @click="show=false">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {companyList,company_renew} from '@/api/member_unit'
import {wechat_pay,payment_order} from '@/api/order'
export default {
    data() {
        return {
            stepLists:[
                {img:'shexie/img/tj.png',name:'已提交',desc:'申请加入',btn:'修改信息',color:"#CC0000",status:0,imgs:'shexie/img/right.png'},
                {img:'shexie/img/zf1.png',name:'未支付',desc:'待支付入会费用',btn:'去支付',color:"#666",status:0,imgs:'shexie/img/right1.png'},
                {img:'shexie/img/sh1.png',name:'未审核',desc:'等待人工审核身份信息',color:"#666",status:0,imgs:'shexie/img/right1.png'},
                {img:'shexie/img/jr1.png',name:'未加入',desc:'未加入',color:"#666",status:0},

                {img:'shexie/img/tj.png',name:'已提交',desc:'申请加入',color:"#CC0000",status:1,imgs:'shexie/img/right.png'},
                {img:'shexie/img/zf.png',name:'已支付',desc:'已支付入会费用',color:"#CC0000",status:1,imgs:'shexie/img/right.png'},
                {img:'shexie/img/sh1.png',name:'审核中',desc:'正在人工审核身份信息',color:"#666",status:1,imgs:'shexie/img/right1.png'},
                {img:'shexie/img/jr1.png',name:'未加入',desc:'未加入',color:"#666",status:1},
                
                {img:'shexie/img/tj.png',name:'已提交',desc:'申请加入',color:"#CC0000",status:3,imgs:'shexie/img/right.png'},
                {img:'shexie/img/zf.png',name:'已支付',desc:'已支付入会费用',color:"#CC0000",status:3,imgs:'shexie/img/right.png'},
                {img:'shexie/img/sh.png',name:'审核通过',desc:'您已通过人工审核身份信息',color:"#CC0000",status:3,imgs:'shexie/img/right.png'},
                {img:'shexie/img/jr.png',name:'已加入',desc:'您已成功加入',color:"#CC0000",status:3,btn:'去续费'},

                {img:'shexie/img/tj.png',name:'已提交',desc:'申请加入',color:"#CC0000",status:2,imgs:'shexie/img/right.png'},
                {img:'shexie/img/zf.png',name:'已支付',desc:'已支付入会费用',color:"#CC0000",status:2,imgs:'shexie/img/right.png'},
                {img:'shexie/img/sh1.png',name:'已驳回',desc:'驳回原因：',ref:'1',btn:'重新提交',color:"#666",status:2,imgs:'shexie/img/right1.png'},
                {img:'shexie/img/jr1.png',name:'未加入',desc:'未加入',color:"#666",status:2},
            ],
            stepList:[
                {img:'shexie/img/sp1.png',name:'阅读入会说明',desc:'阅读',imgs:'shexie/img/arrow.png'},
                {img:'shexie/img/zf2.png',name:'提交会员申请',desc:'用户在线填写申请，请认真填写，已填写的将无法修改',imgs:'shexie/img/arrow.png'},
                {img:'shexie/img/sh3.png',name:'支付会费',desc:'用户填写会员申请信息后，在线支付会员费'},
                {img:'shexie/img/cx4.png',name:'工作人员审核',desc:'用户支付会员费后工作人员会在规定时间内进行信息审核',imgs:'shexie/img/arrow.png'},
                {img:'shexie/img/yj5.png',name:'会员状态查询',desc:'信息审核后新用户可进行会员状态查询',imgs:'shexie/img/arrow.png'},
                {img:'shexie/img/hy6.png',name:'成为会员',desc:'正式成为中国书画文化发展促进会产业专业委员会会员'},
            ],
            memberunitList:{},
            wechatCode:{},
            status:1,
            show:false,
            inter: null,
            interTime: 60,
            isSuccess: false,
            orderId:{},
        };
    },
    created() {
        this.getList()
    },
    mounted() {

    },
    methods: {
        Jump(items,name){
            if(name=='修改信息'){
                this.$router.push({path:'/memberUnitIndex',query:{id:items.apply_id}})
            }else if(name=='去支付'){
                this.show = true
                this.getWechat()
            }else if(name=='重新提交'){
                this.$router.push({path:'/memberUnitIndex',query:{id:items.apply_id}})
            }else if(name=='去续费'){
                this.show = true
                this.getrenew()
            }
        },
        getrenew(){
            company_renew().then(res=>{
                if(res.code==200){
                    this.memberunitList.id = res.data.id
                    this.getWechat()
                }
            })
        },
        getList(){
            companyList().then(res=>{
                if(res.code==200){
                    this.memberunitList = res.data
                }  
            })
        },
        getWechat(){
            wechat_pay(this.memberunitList.id,{from:'pc'}).then(res=>{
                if(res.code==200){
                    var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                        text: res.data.result.code_url, // 需要转换为二维码的内容
                        width: 200,
                        height: 200,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    this.wechatCode = res.data
                    this.startInter()
                }
            })
        },
        //判断有没有支付完成
        startInter() {
            this.destoryInter();
            this.inter = setInterval(() => {
                --this.interTime;
                if (this.interTime % 2 == 0) {
                    this.orderCheck();
                }
                if (this.interTime < 1) {
                    this.destoryInter();
                    //支付时间已过;
                }
            }, 1000);
        },
        destoryInter() {
            if (this.inter) {
                clearInterval(this.inter);
                this.inter = null;
                this.interTime = 60;
                this.show = false
            }
        },
        //是否成功
        orderCheck() {
            payment_order({order_sn:this.wechatCode.order_sn}).then(res=>{
                if(res.code==200){
                    this.$message.success("支付成功!");
                    this.isSuccess = true;
                    this.status = 2
                    clearInterval(this.inter);
                }
            })
        },
    }
};
</script>

<style scoped lang="scss">
    .member_unit{
        .content{
            position: relative;
            margin-top:30px;
            .box{
                height: 188px;
                width: 148px;
                padding: 15px;
                text-align: center;
                border: 1px solid#ccc;
                border-radius: 4px;
                .desc{
                    margin: 10px 0px 15px;
                    font-size: 14px;
                    color: #666;
                }
                .btn{
                    display: inline-block;
                    padding:2px 10px;
                    color: #CD0000;
                    margin: auto;
                    cursor: pointer;
                    text-align: center;
                    border: 1px solid #FF6666;
                    border-radius: 4px;
                }
                img{
                    margin: 10px auto 15px;
                }
            }
            .fx_around{
                .boxs{
                    height: 228px;
                    width: 148px;
                    padding: 15px;
                    text-align: center;
                    border: 1px solid#FFCBCB;
                    border-radius: 4px;
                    .line{
                        width: 40px;
                        height: 2px;
                        background: #CD0000;
                        border-radius: 1px;
                        margin:10px auto;
                    }
                    .desc{
                        font-size: 14px;
                        color: #666;
                    }
                    img{
                        margin: 10px auto 15px;
                    }
                }
            }
            .Connect{
                position: absolute;
                left:120px;
                top: 270px;
                width: 570px;
            }
            .btns{
                width: 340px;
                height: 48px;
                line-height: 48px;
                color: #fff;
                margin: auto;
                text-align: center;
                background: #CC0000;
                border-radius: 24px;
                cursor: pointer;
            }
        }
        .yingyin{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(1,1,1,.4);
            z-index: 99;
            .yboxs{
                width: 480px;
                height: 474px;
                background: #FFFFFF;
                border-radius: 10px;
                .fx{
                    padding:10px 30px;
                    background: #F5F5F5;
                    .cursor{
                        font-size: 24px;
                    }
                }
                .codeimg{
                    margin: 20px 0px;
                    img{
                        padding: 10px;
                        border: 1px solid #ccc;
                        width:200px;
                        height: 200px;
                    }
                    .qrcode{
                        padding: 10px;
                        border: 1px solid #ccc;
                        width:200px;
                        height: 200px;
                        margin: auto;
                    }
                }
                .btns{
                    width: 200px;
                    height: 48px;
                    color: #fff;
                    text-align: center;
                    line-height: 48px;
                    background: #CC0000;
                    border-radius: 24px;
                    cursor: pointer;
                }
            }
        }
    }
</style>
