import service from '@/utils/request'

//会员单位列表
export function companyList(){
    return service({
        url:'api/member_order/2',
        method:'get',
    })
}
//会员单位申请
export function company_add(data){
    return service({
        url:'api/member_unit_apply',
        method:'post',
        data
    })
}
//会员单位修改
export function company_edit(data,id){
    return service({
        url:'api/member_unit_apply/'+id,
        method:'put',
        data
    })
}
//会员单位详情
export function company_info(id){
    return service({
        url:'api/member_unit_apply/'+id,
        method:'get',
    })
}
//会员单位续费
export function company_renew(){
    return service({
        url:'api/member_order/renew',
        method:'get',
    })
}